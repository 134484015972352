(function() {
    'use strict';
	angular.module('eventvods')
		.controller('LoginController', ['SessionManager', '$location', '$rootScope', '$timeout', '$analytics',
		 function(SessionManager, $location, $rootScope, $timeout, $analytics) {
            var vm = this;
            vm.data = {};
			$rootScope.oldGame = $rootScope.game;
			$rootScope.game = 'static';

			function getSession(){
				vm.session = SessionManager.get();
				if(vm.session === null) return $timeout(getSession, 1000);
				if(vm.session !== false) $location.path('/account').search({});
				else $('[data-toggle="tooltip"]').tooltip();
			}
			getSession();

            vm.login = function(form) {
				$('#loginForm input').addClass('ng-touched').removeClass('invalid-pass');
				$analytics.eventTrack('loginFormSubmitted', {  category: 'Login'});
                if (form.$valid)
                    SessionManager.login(vm.data)
                        .then(function() {
							$analytics.eventTrack('loginFormSuccess', {  category: 'Login'});
							$rootScope.$emit('reloadFeatured');
							$location.path('/').search({});
                        })
                        .catch(function() {
							$analytics.eventTrack('loginFormFailed', {  category: 'Login', label: 'Email / password mismatch' });
							$('#loginForm input').addClass('invalid-pass');
                        });
				else
					$analytics.eventTrack('loginFormFailed', {  category: 'Login', label: 'Pre-submission validation fail' });
			};
        }]);
}());
